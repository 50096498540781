import { HttpClient, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Apollo, gql } from 'apollo-angular';
import { BehaviorSubject, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, mergeMap, tap } from 'rxjs/operators';
import { environment } from '@env';
import { AuthMSALService } from './auth-msal.service';



/*@Injectable()
export class HttpClient {

  constructor(private http: Http) {}

  createAuthorizationHeader(headers: Headers) {
    headers.append('Authorization', 'Basic ' +
      btoa('username:password')); 
  }

  get(url) {
    let headers = new Headers();
    this.createAuthorizationHeader(headers);
    return this.http.get(url, {
      headers: headers
    });
  }

  post(url, data) {
    let headers = new Headers();
    this.createAuthorizationHeader(headers);
    return this.http.post(url, data, {
      headers: headers
    });
  }
}
 */

@Injectable({
  providedIn: 'root'
})
export class AtsService {
  permissionHeader;
  userId;

  constructor(private actRoute: ActivatedRoute, private apollo: Apollo, private http: HttpClient,
    private auth: AuthMSALService) {
    this.permissionHeader = {
      'x-jobology-pre-auth': 'true'
    }

    this.userId = this.auth.getClaims('sub');

  }


  recruitersList = [];
  getRecruitersList() {
    for (let i = 0; i < 99; i++) {
      this.recruitersList.push({ name: `Recruteur ${i + 1}`, id: i + 1 });
    }

    return of(this.recruitersList);

  }


  // CANDIDTE PROFIL
  public getProfil = (id, permission?) => {
    return this.apollo.watchQuery<{ profil }>({
      query: gql`query profil($id: Guid!) {
        profil(id: $id) {
          civilite {
            id
            libelle
          }
          cV
          adresse
          competence
          dateCreation
          dateModification
          dateNaissance
          disponibilite
          email
          id
          langues {
            langue {
              id
              libelle
            }
            niveauLangue {
              id
              libelle
            }
          }
          lettreMotivation
          mobile
          nationalite
          annotationsLocalite {
            id
            localite {
              id
              displayName,
              name
            }
          }
          annotationsNiveauExperience {
            id
            niveauExperience {
              id
              libelle
            }
          }
          annotationsNiveauEtude {
            id
            niveauEtude {
              id
              libelle
            }
          }
          formations {
            id
            diplomeObtenu
            nomEcole
            nomFormationOuDiplome
          }
          nom
          origineProfilId
          permis
          photo
          prenom
          reference
          titre
          telephone
          liens {
            id
            lien
          }
          statutMarital
          supports {
            dateCreation
            dateDerniereConnexion
            dateModification
            profilId
            support {
              id
              libelle
              lienLogoCdn
            }
            supportUserId
          }
          annotationsSavoir {
            id
            annotation
          }
          annotationsSavoirEtre {
            id
            annotation
          }
          annotationsSavoirFaire {
            id
            annotation
          }
          annotationsMadSkill {
            id
            annotation
          }
          annotationsMobilite {
            id
            annotation
            localite {
              id
              name
              displayName
            }
          }        
        }      
      }`,
      variables: { id: id },
      context: { headers: permission ? this.permissionHeader : '' }
    })
  }

  refreshProfil$: Subject<any> = new Subject();

  // UPDATE PROFIL
  public updateProfil = (datas, permission?) => {
    const input = {
      id: datas.id,
      adresse: datas.adresse,
      civiliteId: datas.civiliteId,
      dateNaissance: datas.dateNaissance,
      disponibilite: datas.disponibilite,
      email: datas.email,
      mobile: datas.mobile,
      nationalite: datas.nationalite,
      nom: datas.nom,
      permis: datas.permis,
      photoBase64: datas.photo,
      prenom: datas.prenom,
      statutMarital: datas.statutMarital,
      telephone: datas.telephone,
      titre: datas.titre,
      localiteId: datas.localiteId,
      niveauExperienceId: datas.niveauExperienceId,
      niveauEtudeId: datas.niveauEtudeId,
    }

    return this.apollo.mutate<{ updateProfil }>({
      mutation: gql`mutation updateProfil($input:  ProfilUpdateInput!) {
        updateProfil(input: $input) {
          civilite {
            id
            libelle
          }
          cV
          adresse
          competence
          dateCreation
          dateModification
          dateNaissance
          disponibilite
          email
          id
          lettreMotivation
          mobile
          nationalite
          annotationsLocalite {
            id
            localite {
              displayName,
              name
            }
          }
          annotationsNiveauExperience {
            id
            niveauExperience {
              id
              libelle
            }
          }
          annotationsNiveauEtude {
            id
            niveauEtude {
              id
              libelle
            }
          }
          annotationsMobilite {
            id
            annotation
            localite {
              id
              name
              displayName
            }
          }        
          formations {
            id
            diplomeObtenu
            nomEcole
            nomFormationOuDiplome
          }
          nom
          origineProfilId
          permis
          photo
          prenom
          reference
          liens {
            id
            lien
          }
          statutMarital
          supports {
            dateCreation
            dateDerniereConnexion
            dateModification
            profilId
          }
          titre
          telephone
        
        }      
      }`,
      variables: { input: input },
      context: { headers: permission ? this.permissionHeader : '' }
    })
  }

  // CREATE PROFIL
  public createProfil = (datas, permission?) => {
    const input = {
      origineProfilId: datas.origineProfilId,
      sourceId: datas.sourceId,
      cVBase64: datas.cvBase64,
      posteApourvoirId: datas.posteApourvoirId,
      recruteurId: datas.recruteurId,
      nom: datas.nom,
      prenom: datas.prenom
    }

    return this.apollo.mutate<{ createProfil }>({
      mutation: gql`mutation createProfil($input:  ProfilCreateInput!) {
        createProfil(input: $input) {
          id
          nom
          prenom 
        }      
      }`,
      variables: { input: input },
      context: { headers: permission ? this.permissionHeader : '' }
    })
  }

  public createProfilEtapeFluxRecrutement = (datas) => {
    const input = {
      etapeFluxId: datas.etapeFluxId,
      profilId: datas.profilId,
      sourceId: datas.sourceId,
    }

    return this.apollo.mutate<{ createProfilEtapeFluxRecrutement }>({
      mutation: gql`mutation createProfilEtapeFluxRecrutement($input:  ProfilEtapeFluxRecrutementCreateInput!) {
        createProfilEtapeFluxRecrutement(input: $input) {
          id
          date
          dateConsultation
          position
          candidatures {
            dateEntree
            support {
              lienLogoCdn
            }
          }
          profil {
            id
            nom
            prenom
            photo
            origineProfilId
          }
          statutProfil
        }      
      }`,
      variables: { input: input }
    })
  }

  public refreshCreateProfilEtapeFluxRecrutement: Subject<any> = new Subject<any>();

  //Liste des sources
  public getSources = () => {
    return this.apollo.watchQuery<{ sources }>({
      query: gql`query sources {
        sources {
          typeSource {
            id
            libelle
          }
          countProfils
          libelle
          editable
          id
          lienLogoCdn
        }
    }`
    })
  }

  public getTypesSource = () => {
    return this.apollo.watchQuery<{ typesSource }>({
      query: gql`query typesSource {
        typesSource {
          id
          libelle
        }
    }`
    })
  }

  public createSource = (datas, permission?) => {
    const input = {
      libelle: datas.libelle,
      typeSourceId: datas.typeSourceId,
      recruteurId: datas.recruteurId
    }

    return this.apollo.mutate<{ createSource }>({
      mutation: gql`mutation createSource($input:  SourceCreateInput!) {
        createSource(input: $input) {
          id
          libelle
        }
      }`,
      variables: { input: input },
      context: { headers: permission ? this.permissionHeader : '' }
    })
  }

  public deleteSource = (id, permission?) => {
    const input = {
      id: id
    }

    return this.apollo.mutate<{ deleteSource }>({
      mutation: gql`mutation deleteSource($input:  SourceDeleteInput!) {
        deleteSource(input: $input) 
      }`,
      variables: { input: input },
      context: { headers: permission ? this.permissionHeader : '' }
    })
  }

  public updateSource = (datas, permission?) => {
    const input = {
      libelle: datas.libelle,
      id: datas.id,
      typeSourceId: datas.typeSourceId
    }

    return this.apollo.mutate<{ updateSource }>({
      mutation: gql`mutation updateSource($input:  SourceUpdateInput!) {
        updateSource(input: $input) {
          id
          libelle
          typeSource {
            id
            libelle
          }
        }
      }`,
      variables: { input: input },
      context: { headers: permission ? this.permissionHeader : '' }
    })
  }

  refreshSource: Subject<any> = new Subject();

  // LISTE MOTIFS REJET PROFIL
  public getMotifsRejetProfil = () => {
    return this.apollo.watchQuery<{ motifsRejetProfil }>({
      query: gql`query motifsRejetProfil {
      motifsRejetProfil {
        id
        libelle
      }
    }`
    })
  }

  public createProfilSavoir = (datas) => {
    const input = {
      profilId: datas.profilId,
      annotation: datas.annotation
    }

    return this.apollo.mutate<{ createProfilSavoir }>({
      mutation: gql`mutation createProfilSavoir($input:  ProfilSavoirCreate!) {
        createProfilSavoir(input: $input)
      }`,
      variables: { input: input }
    })
  }

  public autocompleteSavoir = (startWith, rows: 10) => {

    return this.apollo.watchQuery<{ autocompleteSavoir }>({
      query: gql`query autocompleteSavoir( $startWith: String!, $rows: Int) {
        autocompleteSavoir(startWith: $startWith, rows: $rows) {
          id
          annotation
        }
      }`,
      variables: { startWith: startWith, rows: rows }
    })
  }

  public deleteProfilSavoir = (datas) => {
    const input = {
      profilId: datas.profilId,
      annotation: datas.annotation
    }

    return this.apollo.mutate<{ deleteProfilSavoir }>({
      mutation: gql`mutation deleteProfilSavoir($input:  ProfilSavoirDelete!) {
        deleteProfilSavoir(input: $input)
      }`,
      variables: { input: input }
    })
  }

  public createProfilSavoirFaire = (datas) => {
    const input = {
      profilId: datas.profilId,
      annotation: datas.annotation
    }

    return this.apollo.mutate<{ createProfilSavoirFaire }>({
      mutation: gql`mutation createProfilSavoirFaire($input:  ProfilSavoirFaireCreate!) {
        createProfilSavoirFaire(input: $input)
      }`,
      variables: { input: input }
    })
  }

  public autocompleteSavoirFaire = (startWith, rows: 10) => {

    return this.apollo.watchQuery<{ autocompleteSavoirFaire }>({
      query: gql`query autocompleteSavoirFaire( $startWith: String!, $rows: Int) {
        autocompleteSavoirFaire(startWith: $startWith, rows: $rows) {
          id
          annotation
        }
      }`,
      variables: { startWith: startWith, rows: rows }
    })
  }

  public deleteProfilSavoirFaire = (datas) => {
    const input = {
      profilId: datas.profilId,
      annotation: datas.annotation
    }

    return this.apollo.mutate<{ deleteProfilSavoirFaire }>({
      mutation: gql`mutation deleteProfilSavoirFaire($input:  ProfilSavoirFaireDelete!) {
        deleteProfilSavoirFaire(input: $input)
      }`,
      variables: { input: input }
    })
  }

  public createProfilSavoirEtre = (datas) => {
    const input = {
      profilId: datas.profilId,
      annotation: datas.annotation
    }

    return this.apollo.mutate<{ createProfilSavoirEtre }>({
      mutation: gql`mutation createProfilSavoirEtre($input:  ProfilSavoirEtreCreate!) {
        createProfilSavoirEtre(input: $input)
      }`,
      variables: { input: input }
    })
  }

  public deleteProfilSavoirEtre = (datas) => {
    const input = {
      profilId: datas.profilId,
      annotation: datas.annotation
    }

    return this.apollo.mutate<{ deleteProfilSavoirEtre }>({
      mutation: gql`mutation deleteProfilSavoirEtre($input:  ProfilSavoirEtreDelete!) {
        deleteProfilSavoirEtre(input: $input)
      }`,
      variables: { input: input }
    })
  }

  public createProfilMadSkill = (datas) => {
    const input = {
      profilId: datas.profilId,
      annotation: datas.annotation
    }

    return this.apollo.mutate<{ createProfilMadSkill }>({
      mutation: gql`mutation createProfilMadSkill($input:  ProfilMadSkillCreate!) {
        createProfilMadSkill(input: $input)
      }`,
      variables: { input: input }
    })
  }

  public autocompleteMadSkill = (startWith, rows: 10) => {

    return this.apollo.watchQuery<{ autocompleteMadSkill }>({
      query: gql`query autocompleteMadSkill( $startWith: String!, $rows: Int) {
        autocompleteMadSkill(startWith: $startWith, rows: $rows) {
          id
          annotation
        }
      }`,
      variables: { startWith: startWith, rows: rows }
    })
  }

  public deleteProfilMadSkill = (datas) => {
    const input = {
      profilId: datas.profilId,
      annotation: datas.annotation
    }

    return this.apollo.mutate<{ deleteProfilMadSkill }>({
      mutation: gql`mutation deleteProfilMadSkill($input:  ProfilMadSkillDelete!) {
        deleteProfilMadSkill(input: $input)
      }`,
      variables: { input: input }
    })
  }

  public createProfilMobilite = (datas) => {
    const input = {
      profilId: datas.profilId,
      localiteId: datas.localiteId
    }

    return this.apollo.mutate<{ createProfilMobilite }>({
      mutation: gql`mutation createProfilMobilite($input:  ProfilMobiliteCreate!) {
        createProfilMobilite(input: $input)
      }`,
      variables: { input: input }
    })
  }

  public deleteProfilMobilite = (datas) => {
    const input = {
      profilId: datas.profilId,
      localiteId: datas.localiteId
    }

    return this.apollo.mutate<{ deleteProfilMobilite }>({
      mutation: gql`mutation deleteProfilMobilite($input:  ProfilMobiliteDelete!) {
        deleteProfilMobilite(input: $input)
      }`,
      variables: { input: input }
    })
  }

  public createProfilLien = (datas) => {
    const input = {
      profilId: datas.profilId,
      lien: datas.lien
    }

    return this.apollo.mutate<{ createProfilLien }>({
      mutation: gql`mutation createProfilLien($input:  ProfilLienCreateInput!) {
        createProfilLien(input: $input) {
          id
          lien
        }
      }`,
      variables: { input: input }
    })
  }

  public deleteProfilLien = (datas) => {
    const input = {
      id: datas.lienId
    }

    return this.apollo.mutate<{ deleteProfilLien }>({
      mutation: gql`mutation deleteProfilLien($input:  ProfilLienDeleteInput!) {
        deleteProfilLien(input: $input)
      }`,
      variables: { input: input }
    })
  }

  public createProfilNiveauLangue = (datas) => {
    const input = {
      profilId: datas.profilId,
      langueId: datas.langueId,
      niveauLangueId: datas.niveauLangueId
    }

    return this.apollo.mutate<{ createProfilNiveauLangue }>({
      mutation: gql`mutation createProfilNiveauLangue($input:  ProfilNiveauLangueCreate!) {
        createProfilNiveauLangue(input: $input)
      }`,
      variables: { input: input }
    })
  }

  public deleteProfilNiveauLangue = (datas) => {
    const input = {
      profilId: datas.profilId,
      langueId: datas.langueId,
      niveauLangueId: datas.niveauLangueId
    }

    return this.apollo.mutate<{ deleteProfilNiveauLangue }>({
      mutation: gql`mutation deleteProfilNiveauLangue($input:  ProfilNiveauLangueDelete!) {
        deleteProfilNiveauLangue(input: $input)
      }`,
      variables: { input: input }
    })
  }

  public getLangues = () => {
    return this.apollo.watchQuery<{ langues }>({
      query: gql`query langues {
          langues {
            id,
            libelle,
          }
        }`
    })
  }

  public getNiveauxLangue = () => {
    return this.apollo.watchQuery<{ niveauxLangue }>({
      query: gql`query niveauxLangue {
          niveauxLangue {
            id,
            libelle,
          }
        }`
    })
  }

  public getContracts = () => {
    return this.apollo.watchQuery<{ contrats }>({
      query: gql`query contrats {
          contrats {
            id,
            libelle,
            necessiteDuree
          }
        }`
    })
  }

  public getNiveauxEtude = () => {
    return this.apollo.watchQuery<{ niveauxEtude }>({
      query: gql`query niveauxEtude {
          niveauxEtude {
            id,
            libelle
          }
        }`
    })
  }


  public getNiveauxExperience = () => {
    return this.apollo.watchQuery<{ niveauxExperience }>({
      query: gql`query niveauxExperience {
          niveauxExperience {
            id,
            libelle
          }
        }`
    })
  }

  public getPermissions = (jobId) => {
    let url = `${environment.path.api2}/cache/posteapourvoirpermissionrecruteur/${jobId}`;

    return this.http.get<any>(url)
      .pipe(
        catchError(err => {
          return throwError(err)
        })
      );
  }

  public getJobStatus = (jobId, guId) => {
    let url = `${environment.path.api2}/posteapourvoir/${jobId}/?recruteurid=${guId}`;

    return this.http.get<any>(url)
      .pipe(
        catchError(err => {
          return throwError(err)
        })
      );
  }

  displayCTAPosteAPourvoir$: Subject<any> = new Subject();
  refreshPosteAPourvoir$: Subject<any> = new Subject();
  getPosteInfosFromView: Subject<any> = new Subject();

  public getPosteApourvoir = (posteApourvoirId: any, permission?) => {
    return this.apollo.watchQuery<{ posteApourvoir }>({
      query: gql`query posteApourvoir($id: Guid!) {
          posteApourvoir(id: $id) {
            id
            dateCreation
            detailPoste
            annotationsContrat {
              contrat {
                id
                libelle
              }
            }
            annotationsNiveauEtude {
              annotation
              id
              niveauEtude {
                id
                libelle
              }
            }
            annotationsNiveauExperience {
              annotation
              id
              niveauExperience {
                id
                libelle
              }
            }
            annotationsLocaliteTravail {
              localite {
                id
                name
                displayName
              }
            }
            libelleDuPoste
            reference
            recruteurs {
              recruteurId
            }
            statut {
              id
              libelle
            }
            estDiffuse
            diffusions {
              dateDiffusion
              dateExpiration
              joursDiffusion
              countCandidatures
              supportOffreId
              supportUrl
              support {
                url
                lienLogoCdn
                libelle
              }
              statut {
                id
                libelle
              }
            }
            modeleFluxRecrutement {
              id
              libelle
            }
          }
        }`,
      variables: { id: posteApourvoirId },
      //context: { headers: permission ? this.permissionHeader : '' }
    })
  }

  public createPosteApourvoir = (datas, permission?) => {
    const input = {
      libelleDuPoste: datas.libelleDuPoste,
      reference: datas.reference,
      detailPoste: datas.detailPoste,
      recruteurCreateurId: datas.recruteurCreateurId,
      localiteId: datas.localiteId,
      afficherSalaire: datas.afficherSalaire,
      dateModification: datas.dateModification,
      disponibilite: datas.disponibilite,
      offreAnonyme: datas.offreAnonyme,
      originePosteApourvoirId: datas.originePosteApourvoirId,
      presentationSociete: datas.presentationSociete,
      teletravail: datas.teletravail,
    }

    return this.apollo.mutate<{ createPosteApourvoir }>({
      mutation: gql`mutation createPosteApourvoir($input: PosteApourvoirCreateInput!) {
          createPosteApourvoir(input: $input) {
            id
            dateCreation
            annotationsActiviteSociete {
              annotation
              id
            }
            annotationsContrat {
              annotation
              id
              contrat {
                id
                libelle
                necessiteDuree
              }
            }
            annotationsLocaliteTravail {
              annotation
              id
            }
            annotationsNiveauEtude {
              annotation
              id
              niveauEtude {
                id
                libelle
              }
            }
            annotationsNiveauExperience {
              annotation
              id
              niveauExperience {
                id
                libelle
              }
            }
            afficherSalaire
            dateModification
            detailPoste
            disponibilite
            libelleDuPoste
            offreAnonyme
            originePosteApourvoirId
            presentationSociete
            reference
            teletravail
          }
        }`,
      variables: { input: input },
      context: { headers: permission ? this.permissionHeader : '' }
    })
  }

  public updatePosteApourvoir = (datas, permission?) => {
    const input = {
      id: datas.id,
      libelleDuPoste: datas.libelleDuPoste,
      reference: datas.reference,
      detailPoste: datas.detailPoste,
      recruteurCreateurId: datas.recruteurCreateurId,
      localiteId: datas.localiteId,
      contratId: datas.contratId,
      niveauEtudeId: datas.niveauEtudeId,
      niveauExperienceId: datas.niveauExperienceId,
      afficherSalaire: datas.afficherSalaire,
      dateModification: datas.dateModification,
      disponibilite: datas.disponibilite,
      offreAnonyme: datas.offreAnonyme,
      originePosteApourvoirId: datas.originePosteApourvoirId,
      presentationSociete: datas.presentationSociete,
      teletravail: datas.teletravail,
      modeleFluxId: datas.modeleFluxId
    }

    return this.apollo.mutate<{ updatePosteApourvoir }>({
      mutation: gql`mutation updatePosteApourvoir($input: PosteApourvoirUpdateInput!) {
          updatePosteApourvoir(input: $input) {
            id
            dateCreation
            annotationsActiviteSociete {
              annotation
              id
            }
            annotationsContrat {
              annotation
              id
              contrat {
                id
                libelle
                necessiteDuree
              }
            }
            annotationsLocaliteTravail {
              annotation
              id
              localite {
                id,
                name,
                displayName
              }
            }
            annotationsNiveauEtude {
              annotation
              id
              niveauEtude {
                id
                libelle
              }
            }
            annotationsNiveauExperience {
              annotation
              id
              niveauExperience {
                id
                libelle
              }
            }
            afficherSalaire
            dateModification
            detailPoste
            disponibilite
            libelleDuPoste
            offreAnonyme
            originePosteApourvoirId
            presentationSociete
            reference
            teletravail
            modeleFluxRecrutement {
              id
              libelle
            }
            statut {
              id
              libelle
            }
          }
        }`,
      variables: { input: input },
      context: { headers: permission ? this.permissionHeader : '' }
    })
  }

  public updateStatusPosteApourvoir = (id, statutId, permission?) => {
    const input = {
      id: id,
      statutId: statutId
    }

    return this.apollo.mutate<{ updatePosteApourvoir }>({
      mutation: gql`mutation updatePosteApourvoir($input: PosteApourvoirUpdateInput!) {
          updatePosteApourvoir(input: $input) {
            id
            statut {
              id
              libelle
            }
          }
        }`,
      variables: { input: input },
      context: { headers: permission ? this.permissionHeader : '' }
    })
  }
  /* 
    public updateStatusPosteApourvoir = (id, status) => {
      const statusId = status === 'close' ? 2 : 1;
      let url = `${environment.path.api2}/posteapourvoir/${id}/statut/${statusId}`;
      const body = '';
  
      return this.http.patch<any>(url, body)
        .pipe(
          // tap(() => this.refetchSubject.next()),
          catchError(err => {
            return throwError(err)
          })
        );
    } */

  public getPostesAPourvoir = (userId, page, pageSize) => {
    return this.apollo.watchQuery<{ posteApourvoirs }>({
      query: gql`query posteApourvoirs($userId: Guid!, $page: Int, $pageSize: Int!) {
          posteApourvoirs(userId: $userId, page: $page, pageSize: $pageSize) {
            id
            dateCreation
            annotationsLocaliteTravail {
              annotation
              id
              localite {
                id,
                name,
                displayName
              }
            }
            dateModification
            libelleDuPoste
            originePosteApourvoirId
            recruteurCreateurId
            reference
          }
        }`,
      variables: { userId: userId, page: page, pageSize: pageSize }
    })
  }

  refetchSubject = new Subject();
  get refetchPosteApourvoirs() {
    return this.refetchSubject.asObservable();
  }
  public getPostesAPourvoir2 = (page = 1, rows = 15, options = <any>{}) => {
    let url = `${environment.path.api2}/cache/postesapourvoir?`;
    //let url = `${environment.path.api2}/sourcing/posteapourvoir/full?recruteurId=${this.userId}&`;

    if (page && rows) {
      url = url + 'page=' + page + '&rows=' + rows
    }

    if (options.colName) {
      url = url + '&sort=' + options.colName + '&sens=' + options.sort;
    }

    if (options.mc) {
      url = url + '&mc=' + options.mc;
    }

    if (options.localiteId) {
      url = url + '&localiteid=' + options.localiteId;
    }

    if (options.datede && options.datea) {
      url = url + `&datede=${options.datede}&datea=${options.datea}`;
    }

    if (options.statut) {
      url = url + `&statutid=${options.statut}`;
    }

    if (options.diffusion !== null) {
      url = url + `&diffusion=${options.diffusion}`;
    }

    return this.http.get<any>(url, /*{ headers: { 'x-jobology-pre-auth': 'postesAPourvoir' } }*/)
      .pipe(
        tap(() => this.refetchSubject.next({})),
        catchError(err => {
          return throwError(err)
        })
      );
  }

  refreshPosteApourvoirs$: Subject<any> = new Subject();

  public getDiffusionsPosteApourvoir = (posteApourvoirId: any) => {
    return this.apollo.watchQuery<{ posteApourvoir }>({
      query: gql`query posteApourvoir($id: Guid!) {
          posteApourvoir(id: $id) {
            id
            dateCreation
            libelleDuPoste
            reference
            statut {
              id
              libelle
            }
            estDiffuse
            diffusions {
              supportOffreId
              supportUrl
              support {
                url
              }
            }
          }
        }`,
      variables: { id: posteApourvoirId }
    })
  }


  // Flux de recrutement
  public fluxRecrutementPosteApourvoir = (posteApourvoirId, permission?) => {
    return this.apollo.watchQuery<{ fluxRecrutementPosteApourvoir }>({
      query: gql`query  fluxRecrutementPosteApourvoir($posteApourvoirId: Guid!) {
           fluxRecrutementPosteApourvoir (posteApourvoirId: $posteApourvoirId) {
            active
            id
            libelle
            categorie {
              id
              libelle
            }
            positionEtape
            profilsEtapeFlux {
              id
              position
              date
              dateConsultation
              statutProfil
              messageRefusId
              profil {
                id
                nom
                prenom
                email
                origineProfilId
                photo
              }
              candidatures {
                candidature
                dateEntree
                dateConsultation
                support {
                  id
                  libelle
                  lienLogoCdn
                }
              }
              motifRejet {
                id
                libelle
              }
            }
          }
        }`,
      variables: { posteApourvoirId: posteApourvoirId },
      context: { headers: permission ? this.permissionHeader : '' }
    })
  }

  public fluxRecrutementPosteApourvoirLight = (posteApourvoirId) => {
    return this.apollo.watchQuery<{ fluxRecrutementPosteApourvoir }>({
      query: gql`query  fluxRecrutementPosteApourvoir($posteApourvoirId: Guid!) {
           fluxRecrutementPosteApourvoir (posteApourvoirId: $posteApourvoirId) {
            id
            positionEtape
          }
        }`,
      variables: { posteApourvoirId: posteApourvoirId }
    })
  }

  public fluxRecrutementPosteApourvoirLight2 = (posteApourvoirId) => {
    return this.apollo.watchQuery<{ fluxRecrutementPosteApourvoir }>({
      query: gql`query  fluxRecrutementPosteApourvoir($posteApourvoirId: Guid!) {
           fluxRecrutementPosteApourvoir (posteApourvoirId: $posteApourvoirId) {
            id
            positionEtape
            profilsEtapeFlux {
              id
              position
              statutProfil
              dateConsultation
            }
          }
        }`,
      variables: { posteApourvoirId: posteApourvoirId }
    })
  }

  public fluxRecrutementPosteApourvoirPosition = (posteApourvoirId) => {
    return this.apollo.watchQuery<{ fluxRecrutementPosteApourvoir }>({
      query: gql`query  fluxRecrutementPosteApourvoir($posteApourvoirId: Guid!) {
           fluxRecrutementPosteApourvoir (posteApourvoirId: $posteApourvoirId) {
            id
            positionEtape
            profilsEtapeFlux {
              id
              position
              statutProfil
            }
          }
        }`,
      variables: { posteApourvoirId: posteApourvoirId }
    })
  }


  createEtapeFluxRecrutement = (datas, permission?) => {
    const input = {
      libelle: datas.libelle,
      // positionEtape: datas.positionEtape,
      categorieEtapeFluxId: datas.categorieEtapeFluxId,
      posteApourvoirId: datas.posteApourvoirId,
      precedenteEtapeFluxId: datas.precedenteEtapeFluxId
    }

    return this.apollo.mutate<{ createEtapeFluxRecrutement }>({
      mutation: gql`mutation createEtapeFluxRecrutement($input: EtapeFluxRecrutementCreateInput!) {
          createEtapeFluxRecrutement(input: $input) {
            active
            id
            libelle
            positionEtape
            categorie {
              id
              hierarchie
              libelle
            }
          }
        }`,
      variables: { input: input },
      context: { headers: permission ? this.permissionHeader : '' }
    })

  }

  public updateEtapeFluxRecrutement = (datas, permission?) => {
    const input = {
      id: datas.id,
      // positionEtape: datas.positionEtape,
      libelle: datas.libelle,
      precedenteEtapeFluxId: datas.precedenteEtapeFluxId,
      categorieEtapeFluxId: datas.categorieEtapeFluxId,
      //active: datas.active
    }

    return this.apollo.mutate<{ updateEtapeFluxRecrutement }>({
      mutation: gql`mutation updateEtapeFluxRecrutement($input: EtapeFluxRecrutementUpdateInput!) {
          updateEtapeFluxRecrutement(input: $input) {
            active
            id
            libelle
            positionEtape
            profilsEtapeFlux {
              id
              position
              date
              dateConsultation
              statutProfil
              profil {
                id
                nom
                prenom
                origineProfilId
                photo
              }
              candidatures {
                dateEntree
                dateConsultation
                support {
                  id
                  libelle
                  lienLogoCdn
                }
              }
            }
          }
        }`,
      variables: { input: input },
      context: { headers: permission ? this.permissionHeader : '' }
    })
  }

  public deleteEtapeFluxRecrutement = (datas) => {
    const input = {
      id: datas.id,
    }

    return this.apollo.mutate<{ deleteEtapeFluxRecrutement }>({
      mutation: gql`mutation deleteEtapeFluxRecrutement($input: EtapeFluxRecrutementDeleteInput!) {
          deleteEtapeFluxRecrutement(input: $input) 
        }`,
      variables: { input: input }
    })
  }

  public refreshEtapeFluxRecrutement: Subject<any> = new Subject<any>();
  public refreshModelNameFluxRecrutement: Subject<any> = new Subject<any>();

  public updateProfilEtapeFluxRecrutement = (datas, permission?) => {
    const input = {
      id: datas.id,
      etapeFluxId: datas.etapeFluxId,
      position: datas.position,
      statutProfil: datas.statutProfil,
      dateConsultation: datas.dateConsultation,
      motifRejetProfilId: datas.motifRejetProfilId,
      messageRefusId: datas.messageRefusId
    }

    return this.apollo.mutate<{ updateProfilEtapeFluxRecrutement }>({
      mutation: gql`mutation updateProfilEtapeFluxRecrutement($input: ProfilEtapeFluxRecrutementUpdateInput!) {
          updateProfilEtapeFluxRecrutement(input: $input) {
            id
            date
            dateConsultation
            position
            statutProfil
            profil {
              id
              nom,
              prenom
              origineProfilId
            }
            messageRefusId
            motifRejet {
              id
              libelle
            }
            candidatures {
              dateConsultation,
              dateEntree,
              support {
                id,
                libelle
                lienLogoCdn
              }
            },
          }
        }`,
      variables: { input: input },
      context: { headers: permission ? this.permissionHeader : '' }
    })
  }

  // MODELES FLUX
  public modeleFluxRecrutement = (modeleId) => {
    return this.apollo.watchQuery<{ modeleFluxRecrutement }>({
      query: gql`query modeleFluxRecrutement($id: Guid!) {
            modeleFluxRecrutement(id: $id) {
              editable
              etapes {
                categorie {
                  id
                  libelle
                  publique
                }
                id
                libelle
                positionEtape
              }
              id
              libelle
            }
          }`,
      variables: { id: modeleId }
    })
  }

  public modelesFluxRecrutement = (userId, permission?) => {

    return this.apollo.watchQuery<{ modelesFluxRecrutement }>({
      query: gql`query modelesFluxRecrutement($contactId: Guid!) {
          modelesFluxRecrutement(contactId: $contactId) {
            id
            libelle
            editable
            countPostesAPourvoir
          }
        }`,
      variables: { contactId: userId },
      context: { headers: permission ? this.permissionHeader : '' }
    })
  }

  public updateEtapeModeleFluxRecrutement = (datas, permission?) => {
    const input = {
      id: datas.id,
      libelle: datas.libelle,
      categorieEtapeFluxId: datas.categorieEtapeFluxId,
      precedenteEtapeFluxId: datas.precedenteEtapeFluxId
      // positionEtape: datas.positionEtape
    }

    return this.apollo.mutate<{ updateEtapeModeleFluxRecrutement }>({
      mutation: gql`mutation updateEtapeModeleFluxRecrutement($input: EtapeModeleFluxRecrutementUpdateInput!) {
          updateEtapeModeleFluxRecrutement(input: $input) {
            categorie {
              id
              libelle
              publique
            }
            id
            libelle
            positionEtape
          }
        }`,
      variables: { input: input },
      context: { headers: permission ? this.permissionHeader : '' }
    })
  }

  public createEtapeModeleFluxRecrutement = (datas, permission?) => {
    const input = {
      modeleFluxId: datas.modeleFluxId,
      libelle: datas.libelle,
      categorieEtapeFluxId: datas.categorieEtapeFluxId,
      precedenteEtapeFluxId: datas.precedenteEtapeFluxId
      //positionEtape: datas.positionEtape
    }

    return this.apollo.mutate<{ createEtapeModeleFluxRecrutement }>({
      mutation: gql`mutation createEtapeModeleFluxRecrutement($input: EtapeModeleFluxRecrutementCreateInput!) {
          createEtapeModeleFluxRecrutement(input: $input) {
            categorie {
              id
              libelle
              publique
            }
            id
            libelle
            positionEtape
          }
        }`,
      variables: { input: input },
      context: { headers: permission ? this.permissionHeader : '' }
    })
  }

  public deleteEtapeModeleFluxRecrutement = (id, permission?) => {
    const input = {
      id: id
    }

    return this.apollo.mutate<{ deleteEtapeModeleFluxRecrutement }>({
      mutation: gql`mutation deleteEtapeModeleFluxRecrutement($input: EtapeModeleFluxRecrutementDeleteInput!) {
          deleteEtapeModeleFluxRecrutement(input: $input) 
        }`,
      variables: { input: input },
      context: { headers: permission ? this.permissionHeader : '' }
    })
  }

  public updateModeleFluxRecrutement = (datas, permission?) => {
    const input = {
      id: datas.id,
      libelle: datas.libelle
    }

    return this.apollo.mutate<{ updateModeleFluxRecrutement }>({
      mutation: gql`mutation updateModeleFluxRecrutement($input: ModeleFluxRecrutementUpdateInput!) {
          updateModeleFluxRecrutement(input: $input) {
            id
            libelle
          }
        }`,
      variables: { input: input },
      context: { headers: permission ? this.permissionHeader : '' }
    })
  }

  public createModeleFluxRecrutement = (datas, permission?) => {
    const input = {
      libelle: datas.libelle,
      posteApourvoirId: datas.posteApourvoirId,
      contactIdCreateur: datas.contactIdCreateur,
    }

    return this.apollo.mutate<{ createModeleFluxRecrutement }>({
      mutation: gql`mutation createModeleFluxRecrutement($input: ModeleFluxRecrutementCreateInput!) {
          createModeleFluxRecrutement(input: $input) {
            editable
            etapes {
              categorie {
                id
                libelle
                publique
              }
              id
              libelle
              positionEtape
            }
            id
            libelle
          }
        }`,
      variables: { input: input },
      context: { headers: permission ? this.permissionHeader : '' }
    })
  }
  public deleteModeleFluxRecrutement = (modeleId, permission?) => {
    const input = {
      id: modeleId
    }

    return this.apollo.mutate<{ deleteModeleFluxRecrutement }>({
      mutation: gql`mutation deleteModeleFluxRecrutement($input: ModeleFluxRecrutementDeleteInput!) {
          deleteModeleFluxRecrutement(input: $input) 
        }`,
      variables: { input: input },
      context: { headers: permission ? this.permissionHeader : '' }
    })
  }

  public categoriesEtapeFluxRecrutement = () => {

    return this.apollo.watchQuery<{ categoriesEtapeFluxRecrutement }>({
      query: gql`query categoriesEtapeFluxRecrutement {
        categoriesEtapeFluxRecrutement {
          id
          libelle
        }
      }`
    })
  }

  focusCard$: Subject<any> = new Subject;
  refreshFluxCTA$: Subject<any> = new Subject();
  refreshFluxCounter$: Subject<any> = new Subject();
}
