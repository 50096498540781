import { COMMA, ENTER, SEMICOLON, SPACE } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { UserService } from 'src/app/core/services/user.service';
import { ErrorsService } from 'src/app/core/services/errors.service';
import { Component, Inject, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AtsService } from 'src/app/core/services/ats.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { map, startWith, take } from 'rxjs/operators';
import clone from 'clone';
import { SourcingService } from 'src/app/core/services/sourcing.service';

@Component({
  selector: 'popin-invitation',
  templateUrl: './popin-invitation.component.html',
  styleUrls: ['./popin-invitation.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PopinInvitationComponent implements OnInit {
  form = new UntypedFormGroup({
    users: new UntypedFormControl([]),
    role: new UntypedFormControl('', Validators.required)
  })

  separatorKeysCodes: number[] = [ENTER, COMMA, SEMICOLON, SPACE];
  emails: any[] = [];

  usersList = [];

  constructor(@Inject(MAT_DIALOG_DATA) public datas, public dialogRef: MatDialogRef<PopinInvitationComponent>,
    private userService: UserService, private snack: MatSnackBar,
    private errorsService: ErrorsService, private sourcingService: SourcingService) { }

  ngOnInit(): void {
    // Select "Recruteur" role by default
    this.datas.roles.map(el => {
      if (el.nom.toLowerCase() === 'recruteur') {
        this.form.controls.role.setValue(el.id);
      }
    })

    // Format datas depending on context, to use it for BULK API
    if (this.datas.usersList) {
      if (this.datas.inviteDepartement) { // Departement

        this.datas.usersList.map(el => {
          this.usersList.push({
            departementRoleId: el.departementRoleId,
            departementId: this.datas.departement.id,
            recruteurId: el.id,
            recruteurRoleId: el.recruteurRoleId ? el.recruteurRoleId : el.roles[0].id
          })

        })
      } else {
        this.datas.usersList.map(el => {
          this.usersList.push({
            recruteurId: el.id,
            roleId: el.recruteurRoleId ? el.recruteurRoleId : el.roles[0].id
          })
        })
      }

    }


    this.errorsService.errorMessage.pipe(take(1)).subscribe(err => {
      this.apiErrors = err;

      this.snack.open(`Une erreur est survenue : ${this.apiErrors.message}`, 'OK', { duration: 1500, panelClass: 'error' })
      this.isSubmitting = false;
    },
      error => {

        this.isSubmitting = false;
        this.snack.open(`Une erreur s\'est produite. Les informations n\'ont pas pu être sauvegardées.`, 'OK', { verticalPosition: 'bottom', duration: 1500, panelClass: 'error' })
      });

  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our email
    if (value) {
      this.emails.push({ email: value, valid: true });
    }

    // Clear the input value
    event.chipInput!.clear();

    this.form.controls.users.setValue(null);
  }

  remove(email: string): void {
    const index = this.emails.indexOf(email);

    if (index >= 0) {
      this.emails.splice(index, 1);
    }
  }

  @ViewChild('chipList') chipList
  emailsNotValid;
  @ViewChild('emailInput') emailInput;
  regexEmail = new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,10}$', 'i');
  // regexEmail = new RegExp('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$');
  // regexEmail = new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$');
  validateEmails() {
    this.emailsNotValid = false;
    const inputValue = this.emailInput.nativeElement.value;

    // If Empty both
    if (inputValue === '' && this.emails.length === 0) {
      this.form.controls.users.addValidators(Validators.required)
    }

    // If one email in input, no need to transform into mat-chip
    // therefore this.emails array is empty
    if (inputValue !== '' && this.emails.length === 0) {

      if (!this.regexEmail.test(inputValue.toLowerCase())) { // If not valid
        this.emailsNotValid = true;
        this.emails.push({ email: inputValue, valid: false });
      } else { // valid
        this.emails.push({ email: inputValue, valid: true });
        this.form.controls.users.setErrors({})
      }

      // clear input
      this.emailInput.nativeElement.value = '';

    } else { // Else many emails in mat-chip list
      this.emails.map(el => {
        if (!this.regexEmail.test(el.email.toLowerCase())) {
          el.valid = false;
          this.emailsNotValid = true;
        }
      })

    }

    if (this.emailsNotValid) {
      this.form.controls.users.setErrors({ pattern: true })
    } else {
      this.form.controls.users.setErrors({})
    }
  }

  isSubmitting;
  apiErrors
  errorCanInvite = false;
  canInviteList;
  formatEmails = [];
  onSubmit(form) {
    this.errorCanInvite = false;
    this.canInviteList = [];
    this.formatEmails = [];

    // Test emails
    this.validateEmails();

    // Format emails
    if (!this.emailsNotValid) {
      this.emails.map(el => {
        this.formatEmails.push({
          email: el.email,
          inviteurRecruteurId: this.datas.userDatas.sub,
          roleId: this.form.controls.role.value,
        });
      })

      this.form.controls.users.setValue(this.formatEmails);
    }

    if (this.form.valid && !this.emailsNotValid) {

      this.isSubmitting = true;

      this.userService.canInviteRecruteur(this.userService.getTenantActive().id, this.formatEmails).pipe(take(1)).subscribe(result => {

        if (result) {
          this.canInviteList = clone(result);

          // Check validity
          this.canInviteList.map(element => {

            if (!element.canInvite) {
              this.errorCanInvite = true;
              element.valid = false;
            } else {
              element.valid = true;
            }
          })

          // Assign to emails list
          this.emails = this.canInviteList;

          // If there isn't error, get the next request
          if (!this.errorCanInvite) {
            this.formatEmails = [];
            // Format emails
            this.emails.map(el => {
              this.formatEmails.push({
                email: el.email,
                inviteurRecruteurId: this.datas.userDatas.sub,
                roleId: this.form.controls.role.value,
              });
            })

            // const input = {
            //   emails: this.formatEmails,
            //   inviteurRecruteurId: this.datas.userDatas.sub,
            //   roleId: this.form.controls.role.value
            // }

            this.userService.inviteRecruteur(this.userService.getTenantActive().id, this.formatEmails).pipe(take(1)).subscribe(result => {
              if (result) {
                let resultDatas = clone(result);


                // If departement invitation
                if (this.datas.inviteDepartement) {

                  // resultDatas.map((user, index) => {

                  //   let infos = {
                  //     departementRoleId: 6,
                  //     departementId: this.datas.departement.id,
                  //     recruteurId: user.id,
                  //     recruteurRoleId: this.form.controls.role.value,
                  //   }
                  //   this.sourcingService.putDepartementMember(infos).pipe(take(1)).subscribe(res => {
                  //     console.log('add member', res)
                  //     let datas = clone(res);

                  //     if (res) {

                  //       if ((index + 1) === resultDatas.length) {
                  //         if (resultDatas.length === 1) {
                  //           this.snack.open('Le membre a bien été ajouté. Un email de notification lui a été envoyé.', 'OK', { duration: 2500 })
                  //         } else if (resultDatas.length > 1) {
                  //           this.snack.open('Les membres ont bien été ajouté. Un email de notification leur a été envoyé.', 'OK', { duration: 2500 })
                  //         }

                  //         this.dialogRef.close({ membersAdded: true });

                  //       }

                  //       this.isSubmitting = false;
                  //     }

                  //   }, err => {
                  //     this.snack.open(`Une erreur est survenue. Veuillez réessayer.`, 'OK', { duration: 1500 })
                  //   })


                  // })

                  let formatResults = [];

                  resultDatas.map(user => {
                    formatResults.push({
                      departementRoleId: 6,
                      departementId: this.datas.departement.id,
                      recruteurId: user.id,
                      recruteurRoleId: this.form.controls.role.value
                    })
                  })

                  // If userslist passed in input
                  if (this.datas.usersList) {
                    this.datas
                    formatResults = formatResults.concat(this.usersList)
                  }

                  this.sourcingService.updateDepartementMembers(this.datas.departement.id, formatResults).pipe(take(1)).subscribe(res => {

                    setTimeout(() => {
                      if (resultDatas.length === 1) {
                        this.snack.open('Le membre a bien été ajouté. Un email de notification lui a été envoyé.', 'OK', { duration: 2500 })
                      } else if (resultDatas.length > 1) {
                        this.snack.open('Les membres ont bien été ajouté. Un email de notification leur a été envoyé.', 'OK', { duration: 2500 })
                      }

                      this.isSubmitting = false;
                      this.dialogRef.close({ membersAdded: true });
                    }, 5500)

                  })



                } else if (this.datas.invitePosteAPourvoir) { // If poste à pourvoir invitation
                  let formatResults = [];

                  resultDatas.map(user => {
                    formatResults.push({
                      recruteurId: user.id,
                      roleId: this.form.controls.role.value
                    })
                  })

                  // If userslist passed in input
                  if (this.datas.usersList) {
                    this.datas
                    formatResults = formatResults.concat(this.usersList)
                  }

                  this.sourcingService.updatePosteMembers(this.datas.posteAPourvoir.id, formatResults).pipe(take(1)).subscribe(res => {


                    setTimeout(() => {
                      if (resultDatas.length === 1) {
                        this.snack.open('Le membre a bien été ajouté. Un email de notification lui a été envoyé.', 'OK', { duration: 2500 })
                      } else if (resultDatas.length > 1) {
                        this.snack.open('Les membres ont bien été ajouté. Un email de notification leur a été envoyé.', 'OK', { duration: 2500 })
                      }

                      this.isSubmitting = false;
                      this.dialogRef.close({ membersAdded: true });
                    }, 5500)


                  }, err => {

                  })
                } else { // If classic invitation
                  this.snack.open(`${this.formatEmails.length > 1 ? 'Les membres ont bien été invités' : 'Le membre a bien été invité'} à rejoindre votre organisation`, 'OK', { duration: 1500 })
                  this.dialogRef.close({ membersAdded: true });
                }

              } else {

              }

              //this.isSubmitting = false;

            }, err => {

              this.snack.open(`[${err.status}] Une erreur est survenue`, 'OK', { duration: 1500, panelClass: 'error' })
              this.isSubmitting = false;
            })
          } else {
            this.isSubmitting = false;
          }

        }
      }, error => {

        this.snack.open(`Une erreur est survenue`, 'OK', { duration: 1500, panelClass: 'error' })
        this.isSubmitting = false;
      })
    }
  }
}

